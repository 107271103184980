import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import { e, row } from 'mathjs';

export default {
  extends: TablePage,
  components: {
  },
  name: 'failure_log_list',

  data() {
    return {
      params: {},
    };
  },
  mounted() {
    this.getConfigList('failure_log_list');
  },

  methods: {

  },
};
