<template>
  <div class="edit-table">
    <el-button @click="cellClick">查看合同表单</el-button>
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      style="z-index:2000"
    >
      <component :is="formName" ref="modalForm" />
      <div class="dialog-footer">
        <el-button icon="el-icon-close" type="danger">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/found/components/modal';
import Vue from 'vue';
import FormPage from './form.vue';

export default {
  name: 'apply',
  components: {
    Modal,
    FormPage,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalConfig: {
        width: '600px',
        title: '标题',
        type: 'Full',
        visible: false,
      },
      formConfig: {},
      formName: '',
    };
  },
  watch: {
    value() {
      this.formConfig = this.value;
    },
  },
  created() {

  },
  methods: {
    // 查看详情
    cellClick() {
      const formPageArg = {
        drawerTitle: '查看',
        code: 'view', // 按钮对应code
        dataCode: this.formConfig.row.code, // 数据code
        formShow: true,
        row: this.formConfig.row,
      };
      this.createPage(formPageArg);
    },
    createPage(arg) {
      const Component = Vue.extend(FormPage);
      const that = this;
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('contract-manage-list');
          },
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
  },
};
</script>

<style lang="less" scepod>
.edit-table{
    margin-left: 60px;
}
</style>
