<template>
  <el-drawer
    custom-class="form_drawer"
    title="移除商品"
    size="80%"
    :visible.sync="formShow"
    @closeDrawer="closeDrawer"
    :modal-append-to-body="false">
    <div style="padding:0 20px;">
      <TableComponent @searchData="searchProduct"
                      :pagination="tablePaginationProduct"
                      :templateData="tableDataProduct"
                      :table-loading="tableLoadingProduct"
                      :searchConfig="searchConfigProduct"
                      :table-config="tableConfigProduct"
      ></TableComponent>
    </div>
    <div class="drawer-button">
      <buttonComponent @clickEvent="removeProduct" :btnType="'primary'" :icon="'el-icon-check'">移除
      </buttonComponent>
      <buttonComponent @clickEvent="formShow = false" :btnType="'danger'" :icon="'el-icon-close'">关闭
      </buttonComponent>
    </div>
  </el-drawer>
</template>

<script>
import TableComponent from '@/src/views/dms/compoment/table-component/table-component';
import buttonComponent from '@/src/views/dms/compoment/button-component/button-component';

export default {
  name: 'product-table',
  components: {
    TableComponent,
    buttonComponent,
  },
  data() {
    return {
      formShow: true,
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tableDataProduct: [],
      tableConfigProduct: {
        checkbox: true,
        checkboxFn: this.selectListData,
        rows: [
          {
            label: '商品编码',
            prop: 'productCode',
          },
          {
            label: '商品名称',
            prop: 'productName',
          },
        ],
      }, // 列表配置
      tablePaginationProduct: { },
      searchConfigProduct: {
        options: [
          {
            valueName: 'productCode',
            placeholder: '请输入商品编号',
          }, {
            valueName: 'productName',
            placeholder: '请输入商品名称',
          },
        ],
      },
      selectTableData: [],
    };
  },
  created() {
    this.searchProduct({});
  },
  methods: {
    closeDrawer(name) {
      this.formShow = false;
    },

    /**
     * 查询商品列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      this.tableLoadingProduct = true;
      this.requestData = 1;
      const params = {
        pageSize: 10,
        ...this.requestData,
        ...requestData,
      };
      const { result: { data, count } } = await this.$http.post(
        '/dms/dms/availableGoods/queryAllAvailableGoodsList', params,
      );
      this.requestData = params;
      this.tablePaginationProduct.total = count;
      this.tableDataProduct = data;
      this.tableLoadingProduct = false;
    },

    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectListData(data) {
      this.selectTableData = [];
      data.forEach((i) => {
        this.selectTableData.push({
          productName: i.productName,
          productCode: i.productCode,
        });
      });
    },

    /**
     * 移除商品
     */
    async removeProduct() {
      await this.$http.post('/dms/dms/contract/updateDetailContractInfo', this.selectTableData);
      this.tablePaginationProduct.pageNum = 1;
      this.$message.success('移除商品成功');
      await this.searchProduct({});
    },
  },
};
</script>

<style scoped lang="less">
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #EBEEF5;
}
</style>
