var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c("el-button", { on: { click: _vm.cellClick } }, [
        _vm._v("查看合同表单"),
      ]),
      _c(
        "Modal",
        {
          staticStyle: { "z-index": "2000" },
          attrs: {
            modalConfig: _vm.modalConfig,
            visible: _vm.modalConfig.visible,
          },
          on: {
            "update:modalConfig": function ($event) {
              _vm.modalConfig = $event
            },
            "update:modal-config": function ($event) {
              _vm.modalConfig = $event
            },
            "update:visible": function ($event) {
              return _vm.$set(_vm.modalConfig, "visible", $event)
            },
          },
        },
        [
          _c(_vm.formName, { ref: "modalForm", tag: "component" }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                { attrs: { icon: "el-icon-close", type: "danger" } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }