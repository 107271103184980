<template>
<div class="choose-product">
  <div class="choose_form">
    <el-select  placeholder="请选择产品" v-model="parms.selectProCode" @change="changeProduct" remote  :remote-method="remoteMethod" filterable clearable size="medium" style="width:300px;margin-bottom: 15px" @clear="clearVal">
        <el-option  v-for="(item,index) in optionsList"
        :key="index"
        :label="item.productName"
        :value="item.productCode"></el-option>
    </el-select>
    <el-input class="ml30" v-model="parms.code" @input="getCode" size="medium" placeholder="请输入合同编码" style="width:300px;"></el-input>
    <el-input class="ml30" v-model="parms.cusName" @input="getcusName" size="medium" placeholder="请输入客户名称" style="width:300px;"></el-input>
    <el-input v-model="parms.cusCode" size="medium" @input="getcusCode" placeholder="请输入客户编码" style="width:300px;"></el-input>
    <el-button type="primary" @click="cellClick" style="margin-left: 18px;">查询</el-button>
  </div>
  <div class="choose_table">
    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        height="340px"
        :header-cell-style="{backgroundColor: '#f2f2f2',color: '#333333',lineHeight:'40px',padding:0}"
        :row-style="{lineHeight:'50px'}"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="合同编号"
          prop="code">
        </el-table-column>
        <el-table-column
          prop="cusName"
          label="客户名称">
        </el-table-column>
        <el-table-column
          prop="cusCode"
          label="客户编码">
        </el-table-column>
      </el-table>
      <div class="table_pag">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="parms.pageNum"
        :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
        :page-size="parms.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="parms.total">
      </el-pagination>
    </div>
    </div>
</div>
</template>
<script>
import request from '@/found/utils/request';

export default {
  name: 'ChooseProduct',
  props: {
    value: Object,
  },
  data() {
    return {
      optionsList: [],
      tableData: [],
      parms: {
        selectProCode: '',
        selectProName: '',
        code: '',
        cusName: '',
        cusCode: '',
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      multipleSelection: [],
    };
  },
  watch: {
    value() {
      this.formConfig = this.value;
    },
  },
  mounted() {
    this.tableList();
    this.getOptionList();
  },
  methods: {

    // 选择产品
    changeProduct(val) {
      this.optionsList.forEach((item) => {
        if (val === item.productCode) {
          this.parms.selectProCode = val;
          this.parms.selectProName = item.productName;
        }
      });
    },
    remoteMethod(val) {
      if (val) {
        this.parms.selectProName = val;
        this.getOptionList();
      }
    },
    getCode(val) {
      console.log('Code', val);
      this.parms.code = val;
    },
    getcusName(val) {
      console.log('cusName', val);
      this.parms.cusName = val;
    },
    getcusCode(val) {
      console.log('cusCode', val);
      this.parms.cusCode = val;
    },

    clearVal() {
      this.parms.selectProCode = '';
      this.parms.selectProName = '';
    },
    cellClick() {
      if (this.parms.selectProCode) {
        this.tableData = [];
        this.parms.pageNum = 1;
        this.tableList();
      } else {
        this.$message.error('请选择产品');
      }
    },
    tableList() {
      request.post('/dms/dms/contract/disableContractPageList', this.parms, {
        headers: {
          functionCode: 'contract-manage-list',
          menucode: 'CRM20210914000002701',
        },
      }).then((res) => {
        console.log(res);
        this.parms.total = res.result.count || 0;
        this.tableData = res.result.data;
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      console.log(val);
      console.log(`每页 ${val} 条`);
      this.parms.pageSize = val;
      this.tableList();
    },
    handleCurrentChange(val) {
      console.log(val);
      console.log(`当前页: ${val}`);
      this.parms.pageNum = val;
      this.tableList();
    },
    getOptionList() {
      request.post('/dms/dms/contract/queryContractProduct', { productName: this.parms.selectProName }).then((res) => {
        console.log(res);
        this.optionsList = res.result.data;
      });
    },
  },
};
</script>

<style lang="less" scepod>
.el-form-item__content .el-input,.el-form-item__content .el-select {
  display: inline-block !important;
}
.choose-product {
  .choose_form{
  display: flex;
   align-items: baseline;
    flex-wrap: wrap;
    .ml30{
      margin-left: 20px,

    }
  }
  .choose_table{
    width: 100%;
    margin-top: 20px;
  }

  .table_pag{
    margin-top: 20px;
    text-align: right;
    .el-pagination{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }

  }

}
</style>
