<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import BottonForm from './botton.vue';

formCreate.component('BottonForm', BottonForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('contract_closure_form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'closeDate') {
        rowData.props = {
          ...rowData.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '00:00:00',
        };
      }
      return rowData;
    },

    formComplete() {
      this.disabled(true, ['cusName']);
      this.setValue({
        cusName: this.formConfig.row.cusName,
        cusCode: this.formConfig.row.cusCode,
        closeContractCode: this.formConfig.row.code,
        botton: this.formConfig,
      });
      const closeDate = this.getRule('closeDate');
      closeDate.on.change = (e) => {
        const newDate = this.dateFormat('YYYY-mm-dd HH:MM:SS', new Date());
        const ext39Date = this.formConfig.row.ext39;
        const isDate = this.isDuringDate(ext39Date, newDate, e);
        if (!isDate) {
          this.$message.error('合同关闭时间需大于合同审批通过时间，不超过当前日期 ');
        }
      };
    },
    isDuringDate(beginDateStr, endDateStr, dateStr) {
      const curDate = new Date(dateStr);
      const beginDate = new Date(beginDateStr);
      const endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    /**
    * @desc 格式化时间
    */
    dateFormat(newfmt, newdate) {
      let ret;
      const date = new Date(newdate);
      let fmt = newfmt;
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      Object.keys(opt).forEach((k) => {
        ret = new RegExp(`(${k})`).exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
          );
        }
      });
      return fmt;
    },
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/dms/dmsclosecontract/closeContract';
        const params = {
          ...formData,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
