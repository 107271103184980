var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "custom-class": "form_drawer",
        title: "移除商品",
        size: "80%",
        visible: _vm.formShow,
        "modal-append-to-body": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.formShow = $event
        },
        closeDrawer: _vm.closeDrawer,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 20px" } },
        [
          _c("TableComponent", {
            attrs: {
              pagination: _vm.tablePaginationProduct,
              templateData: _vm.tableDataProduct,
              "table-loading": _vm.tableLoadingProduct,
              searchConfig: _vm.searchConfigProduct,
              "table-config": _vm.tableConfigProduct,
            },
            on: { searchData: _vm.searchProduct },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-button" },
        [
          _c(
            "buttonComponent",
            {
              attrs: { btnType: "primary", icon: "el-icon-check" },
              on: { clickEvent: _vm.removeProduct },
            },
            [_vm._v("移除 ")]
          ),
          _c(
            "buttonComponent",
            {
              attrs: { btnType: "danger", icon: "el-icon-close" },
              on: {
                clickEvent: function ($event) {
                  _vm.formShow = false
                },
              },
            },
            [_vm._v("关闭 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }